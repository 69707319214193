.room-switcher {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  padding: 0;
}

.room-switcher li {
  border-radius: 3px;
  padding: 0.25em 1em;
}

.room-switcher li.active {
  background-color: var(--bgselected);
}

.room-switcher li a {
  text-decoration: none;
  color: var(--neutral-50);
}

.room-switcher li.active a,
.room-switcher li:hover a {
  color: var(--neutral-color);
}

.sort-routes-by {
  display: block;
  margin-bottom: 1em;
  text-align: center;
}

.sticky {
  background-color: var(--bgcolor);
  position: sticky;
  z-index: 1;
  top: 0;
}

a.edit:link,
a.edit:visited,
a.edit:hover,
a.edit:active {
  color: var(--neutral-50);
  text-decoration: none;
}

a.edit.active:link,
a.edit.active:visited,
a.edit.active:hover,
a.edit.active:active {
  color: var(--accent-color);
}

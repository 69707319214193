.route-item {
  margin-bottom: 0.2em;
}

.route-item label {
  display: flex;
  align-items: baseline;
}

.route-item label > * {
  margin-left: 0.5em;
}

.route-item svg {
  background: transparent;
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
  vertical-align: middle;
}

.route-item strong {
  display: inline-block;
  min-width: 3.5ch;
}

.route-item .description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.route-item .pencil {
  display: inline-block;
  width: 1em;
  text-align: center;
}

.route-item.edit form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 450px;
}

.route-item.edit select {
  height: 1.86em;
  font: inherit;
}

.route-item.edit form input[type="text"] {
  flex-basis: 100%;
  height: 1.86em;
}

.route-item.edit form a {
  line-height: 1.86em;
}
